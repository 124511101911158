<template>
    <uw-content title="款项统计（按合同）" padding="10px">

        <!-- 表格 -->
        <uw-table>

            <!-- 检索 -->
            <template slot="search">
                <el-input @input="TableSearch" v-model="search.name" clearable  size="mini" placeholder="请输入合同名称"></el-input>
                <el-input @input="TableSearch" v-model="search.number" clearable  size="mini" placeholder="请输入合同编号"></el-input>
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 数据 -->
            <template slot="data">
                <vxe-table
                    ref="xTable"
                    show-footer
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :footer-method="TableFooterMethod"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    @sort-change="TableSort"
                    height="100%">

                    <!-- 序号 -->
                    <vxe-column width="60" type="seq" title="序号"></vxe-column>

                    <!-- 合同名称 -->
                    <vxe-column min-width="250" field="name" title="合同名称" sortable>
                        <template #default="{ row }">
                            <el-link type="primary" @click="$refs.drawerContractDetails.Open(row.id)">
                                {{ row.number }} - {{ row.name }}
                            </el-link>
                        </template>
                    </vxe-column>

                    <!-- 合同总额 -->
                    <vxe-column width="120" field="pay_sum_a" title="合同总额" sortable>
                        <template #default="{ row }">
                            <el-link type="primary">
                                ￥{{ row.pay_sum_a.toLocaleString() }}
                            </el-link>
                        </template>
                    </vxe-column>

                    <!-- 已回款金额 -->
                    <vxe-column width="120" field="pay_sum_b" title="已回款金额" sortable>
                        <template #default="{ row }">
                            <el-link type="success">
                                ￥{{ row.pay_sum_b.toLocaleString() }}
                            </el-link>
                        </template>
                    </vxe-column>

                    <!-- 未回款金额 -->
                    <vxe-column width="120" field="pay_sum_c" title="未回款金额" sortable>
                        <template #default="{ row }">
                            <el-link type="warning">
                                ￥{{ row.pay_sum_c.toLocaleString() }}
                            </el-link>
                        </template>
                    </vxe-column>

                    <!-- 退款金额 -->
                    <vxe-column width="120" field="pay_sum_d" title="退款金额" sortable>
                        <template #default="{ row }">
                            <el-link type="danger">
                                ￥{{ row.pay_sum_d.toLocaleString() }}
                            </el-link>
                        </template>
                    </vxe-column>

                    <!-- 回款冲销金额 -->
                    <vxe-column width="120" field="pay_sum_e" title="回款冲销金额" sortable>
                        <template #default="{ row }">
                            ￥{{ row.pay_sum_e.toLocaleString() }}
                        </template>
                    </vxe-column>

                    <!-- 已开票金额 -->
                    <vxe-column width="120" field="pay_sum_f" title="已开票金额" sortable>
                        <template #default="{ row }">
                            ￥{{ row.pay_sum_f.toLocaleString() }}
                        </template>
                    </vxe-column>
                    
                    <!-- 未开票金额 -->
                    <vxe-column width="120" field="pay_sum_g" title="未开票金额" sortable>
                        <template #default="{ row }">
                            ￥{{ row.pay_sum_g.toLocaleString() }}
                        </template>
                    </vxe-column>

                    <!-- 已开票金额【已回款】 -->
                    <vxe-column width="160" field="pay_sum_h" title="已开票金额【已回款】" sortable>
                        <template #default="{ row }">
                            ￥{{ row.pay_sum_h.toLocaleString() }}
                        </template>
                    </vxe-column>

                    <!-- 已开票金额【未回款】 -->
                    <vxe-column width="160" field="pay_sum_i" title="已开票金额【未回款】" sortable>
                        <template #default="{ row }">
                            ￥{{ row.pay_sum_i.toLocaleString() }}
                        </template>
                    </vxe-column>
                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>
        </uw-table>

        <!-- 组件 -->
        <crm-contract-drawer ref="drawerContractDetails" @onChange="TableSearch" />
    </uw-content>
</template>

<script>

export default {
    data () {
        return {

            loading: false,
            
            table: [],
            statistics: {},

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                name: null,
                number: null,
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.loading = true
            this.$http.post(
                '/9api/statistics-pay/contract',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                }
            ).then((rps) => {
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body
                this.statistics = rps.data.statistics

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableSearch()
        },

        /**
        |--------------------------------------------------
        | 表尾数据
        |--------------------------------------------------
        |
        */

        TableFooterMethod() {
            return [
                [
                    '本页',
                    '',
                    '￥' + this.statistics.page?.pay_sum_a.toLocaleString(),
                    '￥' + this.statistics.page?.pay_sum_b.toLocaleString(),
                    '￥' + this.statistics.page?.pay_sum_c.toLocaleString(),
                    '￥' + this.statistics.page?.pay_sum_d.toLocaleString(),
                    '￥' + this.statistics.page?.pay_sum_e.toLocaleString(),
                    '￥' + this.statistics.page?.pay_sum_f.toLocaleString(),
                    '￥' + this.statistics.page?.pay_sum_g.toLocaleString(),
                    '￥' + this.statistics.page?.pay_sum_h.toLocaleString(),
                    '￥' + this.statistics.page?.pay_sum_i.toLocaleString(),
                    '￥' + this.statistics.page?.pay_sum_j.toLocaleString(),
                    '￥' + this.statistics.page?.pay_sum_k.toLocaleString()
                ],
                [
                    '合计',
                    '',
                    '￥' + this.statistics.all?.pay_sum_a.toLocaleString(),
                    '￥' + this.statistics.all?.pay_sum_b.toLocaleString(),
                    '￥' + this.statistics.all?.pay_sum_c.toLocaleString(),
                    '￥' + this.statistics.all?.pay_sum_d.toLocaleString(),
                    '￥' + this.statistics.all?.pay_sum_e.toLocaleString(),
                    '￥' + this.statistics.all?.pay_sum_f.toLocaleString(),
                    '￥' + this.statistics.all?.pay_sum_g.toLocaleString(),
                    '￥' + this.statistics.all?.pay_sum_h.toLocaleString(),
                    '￥' + this.statistics.all?.pay_sum_i.toLocaleString(),
                    '￥' + this.statistics.all?.pay_sum_j.toLocaleString(),
                    '￥' + this.statistics.all?.pay_sum_k.toLocaleString()
                ],
            ]
        },
    }
}
</script>